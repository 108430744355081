var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.alerts
    ? _c(
        "div",
        { staticClass: "glances-alerts-wrapper" },
        _vm._l(_vm.alerts, function (alert, index) {
          return _c("div", { key: index, staticClass: "alert-row" }, [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.tooltip(
                      `${alert.timeAgo}<br>Lasted: ${alert.lasted}`,
                      true
                    ),
                    expression:
                      "tooltip(`${alert.timeAgo}<br>Lasted: ${alert.lasted}`, true)",
                  },
                ],
                staticClass: "time",
              },
              [
                _vm._v(" " + _vm._s(alert.time) + " "),
                alert.ongoing
                  ? _c("span", { staticClass: "ongoing" }, [_vm._v("Ongoing")])
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.tooltip(alert.minMax, true),
                    expression: "tooltip(alert.minMax, true)",
                  },
                ],
                staticClass: "alert-info",
              },
              [
                _c("span", { staticClass: "category" }, [
                  _vm._v(_vm._s(alert.category)),
                ]),
                _vm._v(" - "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(alert.value) + "%"),
                ]),
              ]
            ),
            _c("p", { class: `severity ${alert.severity.toLowerCase()}` }, [
              _vm._v(_vm._s(alert.severity)),
            ]),
          ])
        }),
        0
      )
    : _vm.noResults
    ? _c("div", { staticClass: "no-alerts" }, [
        _c("p", { staticClass: "no-alert-title" }, [
          _vm._v("System is Healthy"),
        ]),
        _c("p", { staticClass: "no-alert-info" }, [
          _vm._v("There are no active alerts"),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }